import { gql } from '@apollo/client';

// User Queries
export const DELETE_USER = gql`
  mutation deleteUser {
    deleteUser {
      status
      message
    }
  }
`;

// User Mutations
export const LOGIN_USER = gql`
  mutation login($password: String!, $email: String!) {
    login(password: $password, email: $email) {
      status
      message
      response {
        ... on LoggedInUser {
          token
          jwtExpiry
          refreshToken
        }
        ... on Validation {
          form
          errors {
            location
            param
            value
            msg
          }
        }
        ... on Issue {
          form
          error
        }
      }
    }
  }
`;

export const REGISTER_USER = gql`
  mutation register($name: String!, $password: String!, $email: String!) {
    register(name: $name, password: $password, email: $email) {
      status
      message
      response {
        ... on RegisteredUser {
          name
          email
          __typename
        }
        ... on Validation {
          form
          errors {
            location
            param
            value
            msg
          }
        }
        ... on Issue {
          form
          error
        }
      }
    }
  }
`;

export const NEW_USER = gql`
  query newUser($token: String!) {
    newUser(token: $token) {
      status
      message
      response
    }
  }
`;

export const RESEND_CONFIRMATION_EMAIL = gql`
  mutation resendConfirmationEmail($email: String!) {
    resendConfirmationEmail(email: $email) {
      status
      message
      response
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $email: String
    $password: String
    $currentPassword: String!
    $name: String
  ) {
    updateUser(
      email: $email
      password: $password
      currentPassword: $currentPassword
      name: $name
    ) {
      status
      message
      response {
        name
        email
      }
    }
  }
`;

export const VALIDATE_UPDATE = gql`
  query validateUpdate($token: String!) {
    validateUpdate(token: $token) {
      status
      message
      response
    }
  }
`;

export const RECOVER_PASSWORD = gql`
  mutation recoverPassword($email: String!) {
    recoverPassword(email: $email) {
      status
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      status
      message
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query authenticateUser {
    authenticateUser {
      status
      message
      response {
        _id
        sub
        switchRegistered
        hasSwissEduIdLinkedAffiliation
        name
        email
        profilePicUrl
        zoteroUserID
        zoteroCollectionName
        isDataLabeler
      }
    }
  }
`;

// Document Queries
export const GET_DOCUMENT = gql`
  query docServiceGetDoc($doc_id: ID!) {
    docServiceGetDoc(id: $doc_id) {
      status
      message
      response {
        _id
        user
        authorName
        title
        content
        lastSavedBy
        time
        locked
        lockedBy {
          _id
          name
        }
        numbering
        orderByAppearance
        library {
          _id
        }
        collaborators {
          _id
          name
          access
          user {
            _id
            name
            profilePicUrl
          }
        }
      }
    }
  }
`;

export const GET_SHARED_DOCUMENTS = gql`
  query {
    docServiceGetSharedDocs {
      status
      message
      response {
        _id
        user
        title
        time
        content
        locked
        collaborators {
          _id
          name
          user
          time
          access
        }
      }
    }
  }
`;

export const GET_DOCUMENTS = gql`
  query {
    docServiceGetDocs {
      status
      message
      response {
        _id
        user
        title
        time
        content
        locked
        collaborators {
          _id
          name
          user
          time
          access
        }
      }
    }
  }
`;

export const POST_DOCUMENT = gql`
  mutation postDocument(
    $title: String!
    $content: String!
    $lastSavedBy: String
    $numbering: Boolean
    $orderByAppearance: Boolean
  ) {
    postDocument(
      title: $title
      content: $content
      lastSavedBy: $lastSavedBy
      numbering: $numbering
      orderByAppearance: $orderByAppearance
    ) {
      status
      message
      response {
        _id
      }
    }
  }
`;

export const SAVE_DOCUMENT = gql`
  mutation saveDocument(
    $id: String!
    $title: String!
    $content: String!
    $name: String!
    $numbering: Boolean
    $orderByAppearance: Boolean
  ) {
    saveDocument(
      id: $id
      title: $title
      content: $content
      name: $name
      numbering: $numbering
      orderByAppearance: $orderByAppearance
    ) {
      status
      message
    }
  }
`;

export const DOCUMENT_CHANGED = gql`
  subscription documentChanged($id: ID!) {
    documentChanged(id: $id) {
      editorID
      status
      message
      response {
        title
        content
        operations
        reference {
          _id
          year
          title
        }
      }
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($id: String!) {
    deleteDocument(id: $id) {
      status
      message
    }
  }
`;

// Bookmark queries
export const GET_BOOKMARKS = gql`
  query {
    getBookmarks {
      status
      message
      response {
        _id
        id_value
        id_field
        id_type
        id_collection
      }
    }
  }
`;

export const DELETE_BOOKMARK = gql`
  mutation deleteBookmark($id_value: String!) {
    deleteBookmark(id_value: $id_value) {
      status
      message
      response {
        _id
      }
    }
  }
`;

export const SAVE_BOOKMARK = gql`
  mutation saveBookmark(
    $id_value: String
    $id_field: String
    $id_type: String
    $id_collection: String
  ) {
    saveBookmark(
      id_value: $id_value
      id_field: $id_field
      id_type: $id_type
      id_collection: $id_collection
    ) {
      status
      message
      response {
        _id
        id_value
        id_field
        id_type
        id_collection
      }
    }
  }
`;

export const SINGLE_PAPER = gql`
  query singlePaper(
    $collection: String!
    $field: String!
    $type: String!
    $id: ID!
    $keywords: String
  ) {
    singlePaper(
      collection: $collection
      field: $field
      type: $type
      id: $id
      keywords: $keywords
    ) {
      status
      message
      response {
        _id
        DOI
        Title
        Content {
          Abstract
          Abstract_Parsed {
            section_id
            section_title
            section_text {
              paragraph_id
              paragraph_text {
                sentence_id
                sentence_text
                sentence_similarity
                cite_spans {
                  start
                  end
                  text
                  ref_id
                }
              }
            }
          }
          Fullbody_Parsed {
            section_id
            section_title
            section_text {
              paragraph_id
              paragraph_text {
                sentence_id
                sentence_text
                sentence_similarity
                cite_spans {
                  start
                  end
                  text
                  ref_id
                }
              }
            }
          }
          Fullbody
        }
        Author {
          FamilyName
          GivenName
        }
        Venue
        PublicationDate {
          Year
          Month
          Day
          Name
        }
        Reference {
          Title
          Author {
            FamilyName
            GivenName
          }
          Venue
          PublicationDate {
            Year
            Month
            Day
            Name
          }
          ReferenceText
          PaperID {
            collection
            id_field
            id_type
            id_value
          }
        }
      }
    }
  }
`;

export const TITLE_SEARCH = gql`
  query titleSearch($Title: String!) {
    titleSearch(Title: $Title) {
      status
      message
      response {
        Title
        collection
        found
        id_field
        id_type
        id_value
        Author {
          AuthorPos
          FamilyName
          GivenName
        }
      }
    }
  }
`;

export const POST_KEYWORD = gql`
  mutation postKeyword($keyword: String!, $indicator: String!) {
    postKeyword(keyword: $keyword, indicator: $indicator) {
      status
      message
      response {
        _id
        indicator
        keyword
      }
    }
  }
`;

export const DELETE_KEYWORD = gql`
  mutation deleteKeyword($keyword_id: String!) {
    deleteKeyword(keyword_id: $keyword_id) {
      status
      message
      response {
        _id
        indicator
        keyword
      }
    }
  }
`;

export const GET_KEYWORDS = gql`
  query getKeywords {
    getKeywords {
      status
      message
      response {
        _id
        keyword
        indicator
      }
    }
  }
`;

// API for parsing natural text query
export const PARSE_KEYWORDS_GIVEN_NATURAL_TEXT = gql`
  query parseNaturalTextQuery($keywords_in_natural_text: String) {
    parseNaturalTextQuery(keywords_in_natural_text: $keywords_in_natural_text) {
      status
      message
      response
    }
  }
`;

// Highlight API
export const HIGHLIGHT_TEXT = gql`
  query highlightText($highlight_source: String!, $paper_list: [metaData!]) {
    highlightText(
      highlight_source: $highlight_source
      paper_list: $paper_list
    ) {
      status
      message
      response {
        _id
        DOI
        Title
        Content {
          Abstract
          Abstract_Parsed {
            section_id
            section_title
            section_similarity
            section_text {
              paragraph_id
              paragraph_similarity
              paragraph_text {
                sentence_id
                sentence_text
                sentence_similarity
                cite_spans {
                  start
                  end
                  text
                  ref_id
                }
              }
            }
          }
          Fullbody_Parsed {
            section_id
            section_title
            section_similarity
            section_text {
              paragraph_id
              paragraph_similarity
              paragraph_text {
                sentence_id
                sentence_text
                sentence_similarity
                cite_spans {
                  start
                  end
                  text
                  ref_id
                }
              }
            }
          }
        }
        Author {
          FamilyName
          GivenName
        }
        Venue
        Reference {
          Title
          Author {
            FamilyName
            GivenName
          }
          Venue
          PublicationDate {
            Year
            Month
            Day
            Name
          }
          ReferenceText
          PaperID {
            collection
            id_field
            id_type
            id_value
          }
        }
        PublicationDate {
          Year
          Month
          Day
          Name
        }
      }
    }
  }
`;

// CITATION GENERATION API

export const CITATION_GENERATION = gql`
  query citationGeneration(
    $model: String!
    $text_before_citation: String!
    $keywords: [String!]
    $cited_paper_id: String!
    $sentences: [String!]
    $intent: String!
  ) {
    citationGeneration(
      model: $model
      text_before_citation: $text_before_citation
      cited_paper_id: $cited_paper_id
      keywords: $keywords
      sentences: $sentences
      intent: $intent
    ) {
      status
      message
      response
    }
  }
`;

// ARGUMENT GENERATION API
export const ARGUMENT_GENERATION = gql`
  query argumentGeneration($premise: String!, $keywords: [String]) {
    argumentGeneration(premise: $premise, keywords: $keywords) {
      status
      message
      response
    }
  }
`;

// MEMSUM SUMMARY API

export const MEMSUM_SUMMARY = gql`
  query memsumSummary($paper_id: String!) {
    memsumSummary(paper_id: $paper_id) {
      status
      message
      response
    }
  }
`;

// Similar Papers API
export const SIMILAR_PAPERS = gql`
  query similarPapers($doc_id: String!) {
    similarPapers(doc_id: $doc_id) {
      status
      message
      response {
        _id
        DOI
        Title
        Content {
          Abstract
        }
        Author {
          FamilyName
          GivenName
        }
        Venue
        # Venue {
        #   Title
        #   Abbreviation
        #   Publisher
        #   Type
        # }
        PublicationDate {
          Year
          Month
          Day
          Name
        }
      }
    }
  }
`;

export const DOCUMENT_SEARCH = gql`
  query documentSearch($search: documentSearchInput!) {
    documentSearch(search: $search) {
      status
      message
      response {
        search_stats {
          DurationFiltering
          DurationIntersection
          DurationMongodbAccess
          DurationRanking
          DurationTotalSearch
          nMatchingDocuments
        }
        results {
          _id
          DOI
          Title
          Content {
            Abstract
            Abstract_Parsed {
              section_id
              section_title
              section_text {
                paragraph_id
                paragraph_text {
                  sentence_id
                  sentence_text
                  sentence_similarity
                }
              }
            }
          }
          Author {
            FamilyName
            GivenName
          }
          Venue
          PublicationDate {
            Year
            Month
            Day
            Name
          }
          id_int
          relevant_sentences
        }
        response {
          collection
          id_field
          id_type
          id_value
        }
      }
    }
  }
`;

export const PAGINATED_SEARCH = gql`
  query paginatedSearch($ids: [metaData], $keywords: String) {
    paginatedSearch(ids: $ids, keywords: $keywords) {
      status
      message
      response {
        _id
        DOI
        Title
        Content {
          Abstract
          Abstract_Parsed {
            section_id
            section_title
            section_text {
              paragraph_id
              paragraph_text {
                sentence_id
                sentence_text
                sentence_similarity
                cite_spans {
                  start
                  end
                  text
                  ref_id
                }
              }
            }
          }
        }
        Author {
          FamilyName
          GivenName
        }
        Venue
        PublicationDate {
          Year
          Month
          Day
          Name
        }
        id_int
        relevant_sentences
      }
    }
  }
`;

export const ADD_PAST_SEARCH = gql`
  mutation addPastSearches(
    $doc_id: String!, 
    $searchTerm: String!,
    $keywords: [String],
    $filters: [filterInput]
    ) {
      addPastSearches(
        doc_id: $doc_id, 
        searchTerm: $searchTerm,
        keywords: $keywords,
        filters: $filters,
      ) {
      status
      message
    }
  }
`;

export const DELETE_PAST_SEARCHES = gql`
  mutation deletePastSearches(
    $doc_id: String!, 
    ) {
      deletePastSearches(
        doc_id: $doc_id, 
      ) {
      status
      message
    }
  }
`;

export const DELETE_PAST_SEARCH = gql`
  mutation deleteSearch(
    $doc_id: String!, 
    $pastSearchID: String!
    ) {
      deleteSearch(
        doc_id: $doc_id, 
        pastSearchID: $pastSearchID, 
      ) {
      status
      message
    }
  }
`;

export const SET_TOGGLE_PAST_SEARCHE_SUBSCRIPTION = gql`
  mutation setTogglePastSearchSubscription(
    $doc_id: String!, 
    $pastSearchID: String!, 
    $togglePastSearcheSubscription: Boolean!
    ) {
      setTogglePastSearchSubscription(
        doc_id: $doc_id, 
        pastSearchID: $pastSearchID, 
        togglePastSearcheSubscription: $togglePastSearcheSubscription
      ) {
      status
      message
    }
  }
`;

export const SET_TOGGLE_PAST_SEARCHES = gql`
  mutation setTogglePastSearches(
    $doc_id: String!, 
    $togglePastSearches: Boolean!, 
    ) {
      setTogglePastSearches(
        doc_id: $doc_id, 
        togglePastSearches: $togglePastSearches, 
      ) {
      status
      message
    }
  }
`;

export const GET_TOGGLE_PAST_SEARCHES = gql`
  query getTogglePastSearches(
    $doc_id: String!, 
    ) {
      getTogglePastSearches(
        doc_id: $doc_id, 
      ) {
      status
      message
      response
    }
  }
`;

export const GET_PAST_SEARCHES = gql`
  query getPastSearches($doc_id: String!) {
    getPastSearches(doc_id: $doc_id) {
      status
      message
      response {
        _id
        searchTerm
        keywords
        filters {
          filterBy
          condition
          value
        }
        subscribed
      }
    }
  }
`;

export const IMPORT_BOOKMARKS = gql`
  mutation importBookmarks($file: Upload!) {
    importBookmarks(file: $file) {
      status
      message
      response {
        Title
        collection
        found
        id_field
        id_type
        id_value
        Author {
          AuthorPos
          FamilyName
          GivenName
        }
      }
    }
  }
`;

export const IMPORT_PDF = gql`
  mutation importPDF($user_id: String!, $base64list: [String]) {
    importPDF(user_id: $user_id, base64list: $base64list) {
      status
      message
    }
  }
`;

export const SAVE_IMPORTS = gql`
  mutation saveImports($bookmarks: [bookmarkInput]!) {
    saveImports(bookmarks: $bookmarks) {
      status
      message
    }
  }
`;

export const SUMMARIZE = gql`
  query summarize(
    $collection: String!
    $id_field: String!
    $id_type: String!
    $id_value: String!
  ) {
    summarize(
      collection: $collection
      id_field: $id_field
      id_type: $id_type
      id_value: $id_value
    ) {
      status
      message
      response {
        paragraph_id
        section_id
        sentence_id
        sentence_text
        tag
      }
    }
  }
`;

export const INVITE_COLLABORATOR = gql`
  mutation inviteCollaborator($doc_id: String, $email: String) {
    inviteCollaborator(doc_id: $doc_id, email: $email) {
      status
      message
    }
  }
`;

export const POST_COLLABORATOR = gql`
  mutation postCollaborator($token: String) {
    postCollaborator(token: $token) {
      status
      message
      response {
        collaborators {
          _id
          name
          access
          user {
            _id
            name
            profilePicUrl
          }
        }
      }
    }
  }
`;

export const UPDATE_COLLABORATOR = gql`
  mutation updateCollaborator(
    $doc_id: String
    $collab_id: String
    $access: String
  ) {
    updateCollaborator(
      doc_id: $doc_id
      collab_id: $collab_id
      access: $access
    ) {
      status
      message
      response {
        collaborators {
          _id
          name
          access
          user {
            _id
            name
            profilePicUrl
          }
        }
      }
    }
  }
`;

export const DELETE_COLLABORATOR = gql`
  mutation deleteCollaborator($doc_id: String, $collab_id: String) {
    deleteCollaborator(doc_id: $doc_id, collab_id: $collab_id) {
      status
      message
      response {
        collaborators {
          _id
          name
          access
          user {
            _id
            name
            profilePicUrl
          }
        }
      }
    }
  }
`;

export const DOCUMENT_UPDATE = gql`
  subscription documentUpdate($doc_id: String!, $user: String!) {
    documentUpdate(doc_id: $doc_id, user: $user) {
      status
      message
      response {
        operations
        user
        name
      }
    }
  }
`;

export const ALERT_DOCUMENT_CHANGE = gql`
  mutation alertDocumentChange(
    $doc_id: String!
    $operations: String!
    $user: String!
    $name: String!
  ) {
    alertDocumentChange(
      doc_id: $doc_id
      operations: $operations
      user: $user
      name: $name
    ) {
      status
    }
  }
`;

export const DOCUMENT_SAVED = gql`
  subscription documentSaved($doc_id: String!) {
    documentSaved(doc_id: $doc_id) {
      status
      message
      response {
        time
        lastSavedBy
      }
    }
  }
`;

export const GET_COACH = gql`
  query getCoach {
    getCoach {
      status
      message
      response {
        manuscript
        manuscripts
      }
    }
  }
`;

export const SET_COACH = gql`
  mutation setCoach($manuscript: Boolean, $manuscripts: Boolean) {
    setCoach(manuscript: $manuscript, manuscripts: $manuscripts) {
      status
      message
      response {
        manuscript
        manuscripts
      }
    }
  }
`;

export const PROFILE_PIC = gql`
  mutation profilePic($file: Upload!) {
    profilePic(file: $file) {
      status
      message
      response
    }
  }
`;

export const DOCUMENT_FIGURE = gql`
  mutation documentFigure($file: Upload!, $doc_id: String!) {
    documentFigure(file: $file, doc_id: $doc_id) {
      status
      message
      response
    }
  }
`;

export const NEW_JWT_FROM_REFRESHTOKEN = gql`
  query newJWTFromRefreshToken {
    newJWTFromRefreshToken {
      status
      message
      response {
        token
        jwtExpiry
        refreshToken
        user {
          _id
          sub
          switchRegistered
          hasSwissEduIdLinkedAffiliation
          name
          email
          profilePicUrl
          zoteroUserID
          zoteroCollectionName
          isDataLabeler
        }
      }
    }
  }
`;

export const LOGOUT_FROM_SESSION = gql`
  query logoutFromSession {
    logoutFromSession {
      status
      message
      response
    }
  }
`;

export const KEYWORDS_BASED_ON_SEARCH = gql`
  query keywordsBasedOnSearch(
    $ranking_variable: String
    $keywords: String!
    $paper_list: [metaData]!
    $manuscript: String
  ) {
    keywordsBasedOnSearch(
      ranking_variable: $ranking_variable
      keywords: $keywords
      paper_list: $paper_list
      manuscript: $manuscript
    ) {
      results
    }
  }
`;

export const CONTACT_US = gql`
  mutation contactUs(
    $name: String!
    $surname: String!
    $email: String!
    $topic: String!
    $subject: String!
    $message: String!
  ) {
    contactUs(
      name: $name
      surname: $surname
      email: $email
      topic: $topic
      subject: $subject
      message: $message
    ) {
      status
      message
      response
    }
  }
`;

export const GET_INVITES = gql`
  query getInvites($doc_id: String!) {
    getInvites(doc_id: $doc_id) {
      status
      message
      response {
        _id
        document
        email
        accepted
        time
      }
    }
  }
`;

export const GET_MY_INVITES = gql`
  query getMyInvites {
    getMyInvites {
      status
      message
      response {
        _id
        document {
          _id
          title
          user {
            _id
            name
          }
        }
        email
        accepted
        time
      }
    }
  }
`;

export const ANSWER_INVITE = gql`
  mutation answerInvite(
    $invite_id: String!
    $email: String!
    $accepted: Boolean!
  ) {
    answerInvite(invite_id: $invite_id, email: $email, accepted: $accepted) {
      status
      message
    }
  }
`;

export const DELETE_INVITE = gql`
  mutation deleteInvite($doc_id: String!, $email: String!) {
    deleteInvite(doc_id: $doc_id, email: $email) {
      status
      message
      response
    }
  }
`;

// Document Queries
export const GET_LOCKED = gql`
  query docServiceGetDoc($doc_id: ID!) {
    docServiceGetDoc(id: $doc_id) {
      status
      message
      response {
        locked
        lockedBy {
          _id
          name
        }
      }
    }
  }
`;

export const SET_LOCK = gql`
  mutation saveDocument($id: String!, $locked: Boolean!) {
    saveDocument(id: $id, locked: $locked) {
      status
      message
    }
  }
`;

export const GENERATE_BIBTEX = gql`
  mutation generateBibtex($paper_list: [paperListInput]) {
    generateBibtex(paper_list: $paper_list) {
      status
      message
      response {
        bibtex
        mla
      }
    }
  }
`;

export const EXPORT_DOCUMENT = gql`
  mutation exportDocument(
    $id: String!
    $title: String!
    $content: String!
    $collaborators: String!
    $bib: String!
    $numbering: Boolean!
    $papers: [metadata]
  ) {
    exportDocument(
      id: $id
      title: $title
      content: $content
      collaborators: $collaborators
      bib: $bib
      numbering: $numbering
      papers: $papers
    ) {
      status
      message
      response
    }
  }
`;

export const EXPORT_DOCX = gql`
  mutation exportDocx(
    $id: String!
    $title: String!
    $content: String!
    $collaborators: String!
    $bib: String!
    $numbering: Boolean!
    $papers: [metadata]
  ) {
    exportDocx(
      id: $id
      title: $title
      content: $content
      collaborators: $collaborators
      bib: $bib
      numbering: $numbering
      papers: $papers
    ) {
      status
      message
      response
    }
  }
`;

export const ADD_PAPER_TO_ZOTERO = gql`
  mutation addPaperToZotero(
    $zoteroCollectionId: String!
    $paper: metadata!
  ) {
    addPaperToZotero(
      zoteroCollectionId: $zoteroCollectionId
      paper: $paper
    ) {
      status
      message
    }
  }
`;

export const ZOTERO_SET_COLLECTION = gql`
  mutation zoteroSetCollection(
    $zoteroCollectionName: String!
  ) {
    zoteroSetCollection(
      zoteroCollectionName: $zoteroCollectionName
    ) {
      status
      message
      zoteroCollectionName
    }
  }
`;

export const GENERATE_KEY = gql`
  mutation generateKey {
    generateKey {
      status
      message
      response
    }
  }
`;

export const REVOKE_KEY = gql`
  mutation revokeKey {
    revokeKey {
      status
      message
      response
    }
  }
`;

export const GET_KEY = gql`
  query getKey {
    getKey {
      status
      message
      response
    }
  }
`;

export const SWITCH_LOGIN = gql`
  mutation switchLogin($code: String!) {
    switchLogin(code: $code) {
      status
      message
      response {
        token
        jwtExpiry
        refreshToken
      }
    }
  }
`;

export const ZOTERO_REDIRECT = gql`
  query zoteroRedirect {
    zoteroRedirect {
      status
      message
      response {
        redirectUrl
        sessionKey
      }
    }
  }
`;

export const ZOTERO_GET_COLLECTIONS = gql`
  query zoteroGetCollections {
    zoteroGetCollections {
      status
      message
      response
    }
  }
`;

export const ZOTERO_ACCESS = gql`
  mutation zoteroAccess(
    $oauthToken: String!
    $oauthVerifier: String!
    $sessionKey: String!
  ) {
    zoteroAccess(
      oauthToken: $oauthToken
      oauthVerifier: $oauthVerifier
      sessionKey: $sessionKey
    ) {
      status
      message
      response
    }
  }
`;

export const ZOTERO_DISCONNECT = gql`
  mutation zoteroDisconnect {
    zoteroDisconnect {
      status
      message
      response
    }
  }
`;

export const POST_MANUSCRIPT_LIBRARY = gql`
  mutation postManuscriptLibrary($doc_id: String!, $bookmark_id: String!) {
    postManuscriptLibrary(doc_id: $doc_id, bookmark_id: $bookmark_id) {
      status
      message
      response {
        _id
      }
    }
  }
`;

export const DELETE_MANUSCRIPT_LIBRARY = gql`
  mutation deleteManuscriptLibrary($doc_id: String!, $bookmark_id: String!) {
    deleteManuscriptLibrary(doc_id: $doc_id, bookmark_id: $bookmark_id) {
      status
      message
      response {
        _id
      }
    }
  }
`;
