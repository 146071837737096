// Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { 
  Box, 
  IconButton, 
  Chip, 
  List, 
  ListItem, 
  ListItemText, 
  Typography, 
  Tooltip, 
  Divider, 
  CircularProgress,
  Switch,
  Stack
} from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DeleteIcon from '@mui/icons-material/Delete';

// Local
import { 
  GET_PAST_SEARCHES, 
  DELETE_PAST_SEARCHES, 
  DELETE_PAST_SEARCH,
  SET_TOGGLE_PAST_SEARCHES, 
  GET_TOGGLE_PAST_SEARCHES,
  SET_TOGGLE_PAST_SEARCHE_SUBSCRIPTION
} from '../../../../Queries';
import { DocumentContext } from '../../../DocumentContext';
import { filterParser, decodeFilter } from './KeywordFilteringScripts'


const PastSearches = ({ setModal, setFilters, collapsedToggle }) => {
  const context = useContext(DocumentContext);
  const [pastSearches, setPastSearches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [saveHistory, setSaveHistory] = useState(true);
  
  // Lazy query to fetch past searches
  const [getPastSearches] = useLazyQuery(GET_PAST_SEARCHES, {
    fetchPolicy: 'network-only',
    variables: {
      doc_id: context.documentId,
    },
    onCompleted: (data) => {
      setLoading(false);
      if (data && data.getPastSearches.response) {
        // Map the list of strings to the pastSearches structure
        const mappedSearches = data.getPastSearches.response.map((s) => ({
          id: s._id,
          subscribed: s.subscribed,
          searchTerm: s.searchTerm,
          keywords: s.keywords,
          parsedKeywords: s.keywords.length > 0 ? ["Content:"+s.keywords.join("|")] : [],
          parsedFilters: s.filters.map(decodeFilter).map(filterParser).filter(f => f !== ""),
          filters: s.filters.map(decodeFilter)
        }));
        setPastSearches(mappedSearches.reverse());
      }
    },
    onError: (err) => {
      setLoading(false);
      setError(err.message);
    }
  });

  // Mutation to delete past searches
  const [deletePastSearches] = useMutation(DELETE_PAST_SEARCHES, {
    fetchPolicy: 'network-only',
    variables: {
      doc_id: context.documentId,
    },
    onCompleted: () => {
      setLoading(false);
      setPastSearches([]);
    },
    onError: (err) => {
      setLoading(false);
      setError(err.message);
    }
  });

  // Mutation to delete a specific past search
  const [deletePastSearch] = useMutation(DELETE_PAST_SEARCH, {
    fetchPolicy: 'network-only',
  });

  // Mutation to set the subscription status for a past search
  const [setTogglePastSearchSubscription] = useMutation(SET_TOGGLE_PAST_SEARCHE_SUBSCRIPTION, {
    fetchPolicy: 'network-only',
  })

  // Mutation to toggle the saving of past searches on or off
  const [setTogglePastSearches] = useMutation(SET_TOGGLE_PAST_SEARCHES, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setLoading(false);
    },
    onError: (err) => {
      setLoading(false);
      setError(err.message);
    }
  });

  // Lazy query to get the toggle of past searches
  const [getTogglePastSearches] = useLazyQuery(GET_TOGGLE_PAST_SEARCHES, {
    fetchPolicy: 'network-only',
    variables: {
      doc_id: context.documentId,
    },
    onCompleted: (data) => {
      setLoading(false);
      setSaveHistory(data.getTogglePastSearches.response)
    },
    onError: (err) => {
      setLoading(false);
      setError(err.message);
    }
  });

  useEffect(() => {
    setLoading(true);
    getPastSearches();
    getTogglePastSearches();
  }, [getPastSearches]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading past searches...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography>Error loading past searches: {error}</Typography>;
  }

  if (pastSearches.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <Typography>No past searches available. Please search some papers first.</Typography>
      </Box>
    );
  }

  const configureSearch = (searchTerm, keywords, filters) => {
    setModal(false)
    context.updateContext('searchTerm', searchTerm);
    context.updateContext('keywords', keywords);
    setFilters(filters);
    if (filters.length > 0)
      collapsedToggle(true)
  }

  return (
    <>
      <Box sx={{ position: 'absolute', top: 20, right: 50, display: 'flex', alignItems: 'center' }}>
        <Tooltip title={`History saving is ${saveHistory ? "enabled" : "disabled"}`} enterDelay={250} enterNextDelay={250}>
          <Switch
            checked={saveHistory}
            onChange={(e) => {
              setSaveHistory(e.target.checked)
              setTogglePastSearches({
                variables: {
                  doc_id: context.documentId,
                  togglePastSearches: e.target.checked
                }
              })
            }}
            inputProps={{ 'aria-label': 'toggle history saving' }}
          />
        </Tooltip>
        <Tooltip title="Delete history" enterDelay={250} enterNextDelay={250}>
          <IconButton 
            edge="end" 
            aria-label="delete history" 
            onClick={() => { 
              setLoading(true);
              deletePastSearches();
             }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ position: 'relative', maxHeight: '400px', overflowY: 'auto', padding: '8px' }}>
        <Divider sx={{ backgroundColor: '1px solid lightgrey', mt: 5 }} />
        <List>
          {pastSearches.map((search, index) => (
            <React.Fragment key={index}>
              <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Tooltip 
                    title={search.searchTerm}
                    enterDelay={2000}
                    enterNextDelay={2000}
                  >
                    <ListItemText
                      primary={
                        <Typography 
                          variant="h6" 
                          sx={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 3
                          }}
                        >
                          {search.searchTerm}
                        </Typography>
                      }
                      sx={{ marginBottom: 0 }}
                    />
                  </Tooltip>
                  <Stack direction="row" spacing={1}>
                    <Tooltip 
                      title={"Delete this past search"}
                    >
                      <IconButton 
                        edge="end" 
                        aria-label="delete-search"
                        onClick={() => {
                          const deletedID = pastSearches[index].id
                          const updatedSearches = [...pastSearches];
                          updatedSearches.splice(index, 1)
                          setPastSearches(updatedSearches);
                          deletePastSearch({
                            variables: {
                                doc_id: context.documentId,
                                pastSearchID: deletedID,
                              }
                          })
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={`Subscription to this past search is ${pastSearches[index].subscribed ? "enabled" : "disabled"}`}
                    >
                      <Switch
                        checked={pastSearches[index].subscribed}
                        onChange={() => {
                          const updatedSearches = [...pastSearches];
                          updatedSearches[index] = {
                            ...updatedSearches[index],
                            subscribed: !updatedSearches[index].subscribed,
                          };
                          setPastSearches(updatedSearches);
                          setTogglePastSearchSubscription({
                            variables: {
                              doc_id: context.documentId,
                              pastSearchID: updatedSearches[index].id,
                              togglePastSearcheSubscription: updatedSearches[index].subscribed, 
                            }
                          })
                        }}
                        inputProps={{ 'aria-label': 'toggle history sbscribing' }}
                      />
                    </Tooltip>
                    <Tooltip 
                      title={"Fill the search form with this past search"}
                    >
                      <IconButton 
                        edge="end" 
                        aria-label="search"
                        onClick={() => configureSearch(search.searchTerm, search.keywords, search.filters)}
                      >
                        <ManageSearchIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px', mt: 1 }}>
                  {search.parsedKeywords.map((keyword, idx) => (
                    <Chip key={idx} label={keyword} variant="outlined" />
                  ))}
                  {search.parsedFilters && search.parsedFilters.length > 0 && search.keywords.length > 0 && (
                    <Divider orientation="vertical" flexItem sx={{ mx: 1, backgroundColor: '1px solid lightgrey' }} />
                  )}
                  {search.parsedFilters && search.parsedFilters.map((parsedFilter, idx) => (
                    <Chip key={`parsedFilter-${idx}`} label={parsedFilter} variant="outlined" />
                  ))}
                </Box>
              </ListItem>
              {index < pastSearches.length - 1 && (
                <Divider sx={{ padding: '5px', backgroundColor: '1px solid lightgrey' }} />
              )}
            </React.Fragment>
          ))}
        </List>
        <Divider sx={{ backgroundColor: '1px solid lightgrey' }} />
      </Box>
    </>
  );
};

export default PastSearches;
